<template>
    <rate class="RateCustom viaSlot" :style="cssVars" :length="5" :value="score" :disabled="!editable" @after-rate="rateChanged">
        <svg v-if="this.type=='restaurant'" class="icon" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-width="2"
            d="m 51.991,8 h -8.01 C 41.153,4.243 36.728,2 32.009,2 27.283,2 22.862,4.244 20.046,8 H 12.008 C 5.392,8.001 0.01,13.383 0.01,20 c 0,6.279 4.85,11.438 11,11.949 V 57 c 0,2.757 2.243,5 5,5 h 31.98 c 0.004,0 0.006,-0.002 0.01,-0.002 0.004,0 0.006,0.002 0.01,0.002 0.002,0 0.003,-10e-4 0.005,-10e-4 V 62 c 2.743,-0.012 4.976,-2.26 4.976,-5.01 V 31.949 c 6.15,-0.511 11,-5.67 11,-11.949 C 63.99,13.383 58.608,8.001 51.991,8 Z M 13.01,57 h 37.979 c 0,4.983888 -37.979,4.993242 -37.979,0 z M 51.99,30 c -0.553,0 -1,0.447 -1,1 V 55 H 13.01 V 31 c 0,-0.553 -0.447,-1 -1,-1 -5.514,0 -10,-4.486 -10,-10 0,-5.514 4.486,-10 10,-10 h 6.748 c -1.142,2.148 -1.748,4.533 -1.748,7 0,0.553 0.447,1 1,1 0.553,0 1,-0.447 1,-1 0,-2.674 0.809,-5.241 2.342,-7.43 C 23.772,6.082 27.757,4 32.01,4 c 4.249,0 8.238,2.083 10.67,5.571 1.524,2.189 2.33,4.759 2.33,7.429 0,0.553 0.447,1 1,1 0.553,0 1,-0.447 1,-1 0,-2.464 -0.604,-4.85 -1.742,-7 h 6.723 c 5.514,0 10,4.486 10,10 0,5.514 -4.487,10 -10.001,10 z"
            />
            <path
            d="m32 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z"
            />
            <path
            d="m23 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z"
            />
            <path
            d="m41 26.997c-.553 0-1 .447-1 1v18.993c0 .553.447 1 1 1s1-.447 1-1v-18.993c0-.553-.447-1-1-1z"
            />
        </svg>
        <svg v-if="this.type=='hotel'" class="icon" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke-width="10"
                d="m499.42 263.047c1.665-4.695 2.58-9.742 2.58-15 0-15.531-7.909-29.248-19.91-37.338v-108.756c0-24.813-20.187-45-45-45h-362.18c-24.813 0-45 20.187-45 45v108.756c-12.001 8.09-19.91 21.807-19.91 37.338 0 5.258.915 10.305 2.58 15h-12.58v192h30v-45.906h452v45.906h30v-192zm-424.51-176.094h362.18c8.271 0 15 6.729 15 15v101.094h-29.957v-15c0-24.813-20.187-45-45-45h-91.133c-11.517 0-22.033 4.354-30 11.495-7.968-7.142-18.483-11.495-30-11.495h-91.133c-24.813 0-45 20.187-45 45v15h-29.957v-101.094c0-8.271 6.729-15 15-15zm166.09 116.094h-121.133v-15c0-8.271 6.729-15 15-15h91.133c8.271 0 15 6.729 15 15zm30-15c0-8.271 6.729-15 15-15h91.133c8.271 0 15 6.729 15 15v15h-121.133zm-231 60c0-8.271 6.729-15 15-15h402c8.271 0 15 6.729 15 15s-6.729 15-15 15h-402c-8.271 0-15-6.729-15-15zm-10 45h452v86.094h-452z" 
            />
        </svg>
        <svg v-if="this.type=='activity'" class="icon" viewBox="0 0 50 50">
            <path stroke-width="2"
                d="M6.5,43.386h36.437c3.584,0,6.501-2.894,6.501-6.448V17.835c0-3.552-2.91-6.441-6.487-6.448 l0.05-0.001v-1.002c0.035-0.551-0.321-0.998-0.874-0.998h-3.5c-0.553,0-1,0.447-1,1v1h-1.293c-0.534-3.025-3.196-5.334-6.397-5.334 H19.5c-3.2,0-5.861,2.309-6.396,5.334H6.5c-3.584,0-6.5,2.893-6.5,6.449v19.103C0,40.492,2.916,43.386,6.5,43.386z M2.149,17.835 c0-2.371,1.952-4.299,4.351-4.299H13h2.149l0.167-2.15c0.501-1.828,2.178-3.184,4.184-3.184h10.437 c2.007,0,3.684,1.355,4.185,3.184l0.167,2.15h2.149h6.499c2.4,0,4.352,1.928,4.352,4.299v19.103c0,2.37-1.952,4.299-4.352,4.299 H6.5c-2.398,0-4.351-1.929-4.351-4.299V17.835z M18.238,13.052H31.05c0.449,0,0.812-0.363,0.812-0.812s-0.363-0.812-0.812-0.812 H18.238c-0.448,0-0.812,0.363-0.812,0.812S17.79,13.052,18.238,13.052z M24.72,39.378c6.689,0,12.132-5.442,12.132-12.132 c0-6.689-5.442-12.132-12.132-12.132s-12.132,5.442-12.132,12.132S18.03,39.378,24.72,39.378z M24.72,17.145 c5.57,0,10.101,4.531,10.101,10.102c0,5.57-4.531,10.102-10.101,10.102s-10.102-4.531-10.102-10.102S19.149,17.145,24.72,17.145z M18.326,27.923c0-4.023,3.272-7.295,7.295-7.295c0.374,0,0.677-0.303,0.677-0.677s-0.303-0.677-0.677-0.677 c-4.768,0-8.648,3.88-8.648,8.648c0,0.374,0.303,0.677,0.677,0.677C18.023,28.6,18.326,28.297,18.326,27.923z"
            />
        </svg>
    </rate>
</template>

<script>
import { mapState } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent({
    name: "Rating",
    props: {
        score: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['colors']),
        cssVars() {
            return { '--color': this.colors[this.type] }
        }
    },
    methods: {
        rateChanged(newRate) {
            this.$emit("after-rate", newRate);
        }
    }
})
</script>

<style lang="scss">
.RateCustom.Rate .icon {
  width: 25px;
  height: 25px;
}

.RateCustom.Rate .Rate__star {
  cursor: auto;
}

.RateCustom.Rate .Rate__star.hover {
  color: var(--color);
  cursor: auto;
}
</style>